import React from 'react';

import Carousel from '../../../Components/Carousel/Carousel';
import Image from '../../../Components/Image/Image';

import styles from './screenshot.module.css';

const MobileSlider = ({ SCREENSHOTS_MOBILE }) => (
  <div className={styles.mobile_slider}>
    <Carousel interval={2000} indicators={false}>
      {SCREENSHOTS_MOBILE.map((screenshot, index) => (
        <div
          key={`${screenshot}+${index}`}
          className={`${styles.slide} center`}>
          <Image
            className={styles.pic}
            src={screenshot.screen}
            webpImage={screenshot.screen_webp}
            alt="screenshot"
          />
        </div>
      ))}
    </Carousel>
  </div>
);

export default MobileSlider;
