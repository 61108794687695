import React from 'react';

import MobileSlider from './MobileSlider';
import DesktopSlider from './DesktopSlider';

import { SCREENSHOT_TEXT_1, SCREENSHOT_TEXT_2 } from '../../../Constants';
import screenshot1_png from '../../../Assets/Screenshot/1.png';
import screenshot1_webp from '../../../Assets/Screenshot/1.webp';
import screenshot2_png from '../../../Assets/Screenshot/2.png';
import screenshot2_webp from '../../../Assets/Screenshot/2.webp';
import screenshot3_png from '../../../Assets/Screenshot/3.png';
import screenshot3_webp from '../../../Assets/Screenshot/3.webp';
import screenshot4_png from '../../../Assets/Screenshot/4.png';
import screenshot4_webp from '../../../Assets/Screenshot/4.webp';
import screenshot5_png from '../../../Assets/Screenshot/5.png';
import screenshot5_webp from '../../../Assets/Screenshot/5.webp';
import screenshot6_png from '../../../Assets/Screenshot/6.png';
import screenshot6_webp from '../../../Assets/Screenshot/6.webp';
import screenshot7_png from '../../../Assets/Screenshot/7.png';
import screenshot7_webp from '../../../Assets/Screenshot/7.webp';
import screenshot8_png from '../../../Assets/Screenshot/8.png';
import screenshot8_webp from '../../../Assets/Screenshot/8.webp';
import screenshot9_png from '../../../Assets/Screenshot/9.png';
import screenshot9_webp from '../../../Assets/Screenshot/9.webp';

import styles from './screenshot.module.css';

const SCREENSHOTS_DESKTOP = [
  {
    screen: [
      { pic: screenshot1_png, pic_webp: screenshot1_webp },
      { pic: screenshot4_png, pic_webp: screenshot4_webp },
      { pic: screenshot3_png, pic_webp: screenshot3_webp }
    ]
  },
  {
    screen: [
      { pic: screenshot2_png, pic_webp: screenshot2_webp },
      { pic: screenshot6_png, pic_webp: screenshot6_webp },
      { pic: screenshot5_png, pic_webp: screenshot5_webp }
    ]
  },
  {
    screen: [
      { pic: screenshot7_png, pic_webp: screenshot7_webp },
      { pic: screenshot8_png, pic_webp: screenshot8_webp },
      { pic: screenshot9_png, pic_webp: screenshot9_webp }
    ]
  }
];

const SCREENSHOTS_MOBILE = [
  {
    screen: screenshot4_png,
    screen_webp: screenshot4_webp
  },
  {
    screen: screenshot6_png,
    screen_webp: screenshot6_webp
  },
  {
    screen: screenshot8_png,
    screen_webp: screenshot8_webp
  },
  {
    screen: screenshot1_png,
    screen_webp: screenshot1_webp
  },
  {
    screen: screenshot5_png,
    screen_webp: screenshot5_webp
  },
  {
    screen: screenshot2_png,
    screen_webp: screenshot2_webp
  },
  {
    screen: screenshot7_png,
    screen_webp: screenshot7_webp
  },
  {
    screen: screenshot3_png,
    screen_webp: screenshot3_webp
  },
  {
    screen: screenshot9_png,
    screen_webp: screenshot9_webp
  }
];

const Screenshot = () => (
  <div
    data-testid="screenshot"
    id="screenshot"
    className={`${styles.container} center`}>
    <div className={styles.heading}>
      <p className="heading_text">{SCREENSHOT_TEXT_1}</p>
      <p className="body_text">{SCREENSHOT_TEXT_2}</p>
    </div>

    {/* MOBILE CAROUSEL */}
    <MobileSlider SCREENSHOTS_MOBILE={SCREENSHOTS_MOBILE} />

    {/* DESKTOP CAROUSEL */}
    <DesktopSlider SCREENSHOTS_DESKTOP={SCREENSHOTS_DESKTOP} />
  </div>
);

export default Screenshot;
