import React from 'react';

import Carousel from '../../../Components/Carousel/Carousel';
import Image from '../../../Components/Image/Image';

import styles from './screenshot.module.css';

const DesktopSlider = ({ SCREENSHOTS_DESKTOP }) => (
  <div className={styles.desktop_slider}>
    <Carousel interval={3000} indicators={false} transition="none">
      {SCREENSHOTS_DESKTOP.map((screenshot, index) => (
        <div
          key={`${screenshot}+${index}`}
          className={`${styles.slide} center`}>
          {screenshot.screen.map((screen, index) => (
            <Image
              key={`${screen}+${index}`}
              className={styles.pic}
              src={screen.pic}
              webpImage={screen.pic_webp}
              alt="screenshot"
            />
          ))}
        </div>
      ))}
    </Carousel>
  </div>
);

export default DesktopSlider;
